<template>
  <div>
    <swiper
    :modules="modules"
    :slides-per-view="1"
    :space-between="50"
    navigation
    :pagination="{ clickable: true }"
    @swiper="onSwiper"
    @slideChange="onSlideChange"
    >
<swiper-slide v-for="item in mostra" v-bind:key="item">
<a :href="item.url">
<img :src="item.id" alt="">
</a>
</swiper-slide>
      
      ...
    </swiper>

  </div>
</template>
<script>
let entrada = document.getElementById('entrada').value;
  // Import Swiper Vue.js components
  import { Navigation, Pagination, A11y } from 'swiper';

  // Import Swiper Vue.js components
  import { Swiper, SwiperSlide } from 'swiper/vue';

  // Import Swiper styles
  import 'swiper/css';
  import 'swiper/css/navigation';
  import 'swiper/css/pagination';

  export default {
    components: {
      Swiper,
      SwiperSlide,
    },
    data(){
      return{
        mostra : ''
      }
    },
    setup() {
      const onSwiper = (swiper) => {
        console.log(swiper);
      };
      const onSlideChange = () => {
        console.log('slide change');
      };
      return {
        onSwiper,
        onSlideChange,
        modules: [Navigation, Pagination, A11y],
      };
    },
    mounted(){
      this.mostra = JSON.parse(entrada);

    }
  };
</script>
<style>
#app{
  width:100%;
  height: 100%;
}
#app > div{
  height: 100%;
}
.swiper{
  height:100%;
}
.swiper-slide > a > img{
  width: 100%;
  height:100%;
  object-fit: cover;
}
.swiper-button-prev,.swiper-button-next{
  color: white;

}
.swiper-pagination-bullet{
  background:white;
}
.swiper-pagination-bullet-active{
  background:white;
  opacity:100%;
}
</style>
